 .body, html{
    height: 100%;
}

.overlay {
  height: 100%;
  width: 0%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgba(159,134,65, 0.7);
  /* opacity: 0.7; */
  overflow-x: hidden;
  transition: 0.5s;
}

.overlay-content {
  position: relative;
  top: 25%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
  
}


.btn-primary{
  border-radius: 79px;
  background:#4267B2;
}

.ellipse{
  position: absolute;
width: 219px;
height: 219px;
left: 641px;
top: calc(50% - 219px/2 - 44.5px);

background: #4267B2;
opacity: 0.9;
filter: blur(350px);
}

.eclipse{
  position: absolute;
width: 219px;
height: 219px;
left: calc(50% - 219px/2 + 0.5px);
top: calc(50% - 219px/2 - 0.5px);

background: #4267B2;
opacity: 0.9;
filter: blur(350px);
}

.inputcontainer{
  background: #FFFFFF;
border: 0.5px solid rgba(37, 37, 37, 0.1);
/* Drop Shadow */

box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
border-radius: 40px;
}

.card-custom{
  /* secondary color */

background: #FFFFFF;
/* Drop Shadow */

box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
border-radius: 12px;
}

.form-control:focus {
  border: 0px;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0);
} 



.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  background: #F6F6F6;
  font-weight: bold;
}

/*
.separator {
    display: flex;
    align-items: center;
    text-align: center;
    padding:25px;
  }
  
  .separator::before,
  .separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #000;
  }
  
  .separator:not(:empty)::before {
    margin-right: .25em;
  }
  
  .separator:not(:empty)::after {
    margin-left: .25em;
  }
  

  .hoverablecard:hover{
    transform: scale(1.05);
    box-shadow: 0 15px 10px -10px rgba(31, 31, 31, 0.5);
    transition: all 0.3s ease;
} */